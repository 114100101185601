import React, { useState } from "react";
import GeneralInfo from "./GeneralInfo";
import {
  generalInformation,
  gettingStarted,
  verificationAndSecurity,
  investing,
  autoInvest,
  taxation,
} from "../../Utils/Reusables";

const RenderHelpDeskComponent = ({ tab, searchValue }) => {
  const [expandedKeys, setExpandedKeys] = useState({
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
  });

  const handleExpand = (tabKey, newExpandedKeys) => {
    setExpandedKeys((prevKeys) => ({
      ...prevKeys,
      [tabKey]: newExpandedKeys,
    }));
  };

  const renderComponent = () => {
    switch (tab) {
      case 1:
        return (
          <GeneralInfo
            information={generalInformation}
            title="General Information"
            expandedKeys={expandedKeys[1]}
            onExpand={(keys) => handleExpand(1, keys)}
            searchValue={searchValue}
          />
        );
      case 2:
        return (
          <GeneralInfo
            information={gettingStarted}
            title="Getting Started"
            expandedKeys={expandedKeys[2]}
            onExpand={(keys) => handleExpand(2, keys)}
            searchValue={searchValue}
          />
        );
      // case 3:
      //   return (
      //     <GeneralInfo
      //       information={verificationAndSecurity}
      //       title="Verification and Security"
      //     />
      //   );
      case 3:
        return (
          <GeneralInfo
            information={investing}
            title="Investing"
            expandedKeys={expandedKeys[3]}
            onExpand={(keys) => handleExpand(3, keys)}
            searchValue={searchValue}
          />
        );
      case 4:
        return (
          <GeneralInfo
            information={autoInvest}
            title="Auto-investment"
            expandedKeys={expandedKeys[4]}
            onExpand={(keys) => handleExpand(4, keys)}
            searchValue={searchValue}
          />
        );
      case 5:
        return (
          <GeneralInfo
            information={taxation}
            title="Taxation"
            expandedKeys={expandedKeys[5]}
            onExpand={(keys) => handleExpand(5, keys)}
            searchValue={searchValue}
          />
        );
      default:
        return (
          <div style={{ padding: "20px", textAlign: "center" }}>
            <h3>No Content Available</h3>
            <p>Please select a valid tab to view the content.</p>
          </div>
        );
    }
  };

  return <div>{renderComponent()}</div>;
};

export default RenderHelpDeskComponent;
