import React, { useEffect, useState } from "react";
import DeviceMobile from "../../Assets/Images/IconSet2fa.svg";
import OtpInput from "react18-input-otp";
import { Link } from "react-router-dom";
import ButtonDefault from "../../Components/ButtonDefault/ButtonDefault.jsx";

import GlobalVariabels from "../../Utils/GlobalVariabels.js";

import totpIcon from "../../Assets/Images/IconSet_totp.svg";

const TwoFAComponent = ({
  onInit,
  onAuthenticate,
  secFactorAuth,
  loader,
  codes,
  mobileNo,
  usedIn,
}) => {
  const [otp, setOtp] = useState("");
  const [code, setCode] = useState(codes || "");
  const [time, setTime] = useState(59);
  const [isTimerRunning, setIsTimerRunning] = useState(true);
  const [mobile, setMobile] = useState(mobileNo || "");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  let currentEnv = GlobalVariabels.NODE_ENV;

  const resendInit = async () => {
    setIsTimerRunning(true);
    setTime(59);
    await onInit()
      .then((res) => {
        if (res) {
          setCode(res.authenticationCode || "");
          setMobile(res.mobilePhone || "");
        }
      })
      .catch(() => {
        console.error("Error re-sending 2FA code");
      });
  };

  useEffect(() => {
    const intervalTimer = setInterval(() => {
      if (isTimerRunning) {
        setTime((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(intervalTimer);
            setIsTimerRunning(false);
          }
          return prevTime - 1;
        });
      }
    }, 1000);

    return () => clearInterval(intervalTimer);
  }, [isTimerRunning]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleAuthentication = async () => {
    if (!otp) {
      console.error("Please enter OTP");
      return;
    }

    await onAuthenticate(otp)
      .catch(() => {
        console.error("Authentication failed");
      })
      .finally(() => {
        console.error("Authentication passed");
      });
  };

  const inputStyle = {
    borderRadius: "12px",
    border: "1px solid rgba(26, 32, 44, 0.10)",
    background: "rgba(255, 255, 255, 0.80)",
    width: windowWidth <= 380 ? "35px" : "40px",
    marginLeft: "4px",
    marginRight: "4px",
    height: windowWidth <= 380 ? "35px" : "40px",
    fontSize: "14px",
    padding: "4px 12px",
    color: "var(--kilde-blue)",
  };

  return (
    <div
      className={
        usedIn === "login"
          ? "sb-onboarding-form-container"
          : "twofa-addresschange"
      }
    >
      <div className="sb-flex-column-item-center mb-28">
        {secFactorAuth === "TOTP" ? (
          <img src={totpIcon} alt="totp" className="kl-mobile" />
        ) : (
          <img src={DeviceMobile} alt="sms" className="kl-mobile" />
        )}
      </div>
      <div>
        <p className="kl-title  m-0">Two-Factor Authentication</p>

        <p className="kl-subtitle mt-8 mb-28">
          {secFactorAuth === "TOTP"
            ? "Enter current one time password from your Google Authentication app."
            : `Enter the verification code we send to ${mobile}`}
        </p>
      </div>

      <div className="sb-otp-input-div mb-28">
        <OtpInput
          value={otp}
          onChange={setOtp}
          inputStyle={inputStyle}
          numInputs={6}
          renderSeparator={<span></span>}
          isInputNum={true}
          inputProps={{ type: "number", inputMode: "numeric" }}
          renderInput={(props) => (
            <input {...props} type="number" inputMode="numeric" />
          )}
          shouldAutoFocus
        />
      </div>

      {secFactorAuth === "SMS" && isTimerRunning === true && (
        <div className="kd-resend">
          <p className="mb-16">
            Resend code in{" "}
            <span id="timer">{isTimerRunning ? `(${time}s)` : ""}</span>{" "}
          </p>
        </div>
      )}
      {secFactorAuth === "SMS" && isTimerRunning === false && (
        <p
          className="sb-twofa-link mb-16"
          onClick={isTimerRunning ? null : resendInit}
        >
          <Link> Resend Code</Link>
        </p>
      )}

      <div className="sb-TwoFa-actions m-0">
        {secFactorAuth === "SMS" && currentEnv === "DEV" && (
          <div>
            <p style={{ textAlign: "right", color: "#ddd", margin: "0px" }}>
              Authentication Code: {code}
            </p>
          </div>
        )}
        <ButtonDefault
          title="Authenticate"
          block={true}
          loading={loader}
          onClick={handleAuthentication}
          style={{ marginTop: "12px" }}
        />
      </div>

      <p className="kl-subtitle mt-12">
        Didn’t get the code ?{"  "}
        <Link
          style={{ fontSize: 14, color: "var(--kilde-blue)" }}
          to="tel:+65 695 077 68"
        >
          Call Us
        </Link>
      </p>
    </div>
  );
};

export default TwoFAComponent;
