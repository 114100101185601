/* eslint-disable react-hooks/exhaustive-deps */
import { Table } from "antd";
import React, { useEffect, useState } from "react";

const AssetsInfo = ({ TrancheRes }) => {
  const [keyFinancialsCsv, setKeyFinancialsCsv] = useState([]);

  useEffect(() => {
    const csvData = TrancheRes?.tranche?.details?.keyFinancialsCsv;

    if (csvData !== undefined && csvData !== null) {
      const rows = csvData?.split("\n");
      const headers = rows[0]
        .split(";")
        .map((header) => header.replace(/"/g, "").trim());
      const financialsData = rows.slice(1).map((row) => {
        const values = row
          .split(";")
          .map((value) => value.replace(/"/g, "").trim());
        const financial = {};
        headers.forEach((header, index) => {
          financial[header] = isNaN(values[index])
            ? values[index]
            : parseFloat(values[index].replace(",", "."));
        });
        return financial;
      });
      setKeyFinancialsCsv(financialsData);
    }
  }, []);

  const generateColumns = (data) => {
    if (data.length === 0) return [];
    return Object.keys(data[0]).map((key) => ({
      title: key,
      dataIndex: key.replace(/[\s,]/g, ""),
    }));
  };

  const generateDataForTable = (data) => {
    return data.map((item, index) => {
      const modifiedItem = {};
      Object.keys(item).forEach((key) => {
        modifiedItem[key.replace(/[\s,]/g, "")] = item[key];
      });
      return {
        key: index,
        ...modifiedItem,
      };
    });
  };

  const financialColumns = generateColumns(keyFinancialsCsv);
  const financialDataForTable = generateDataForTable(keyFinancialsCsv);

  return (
    <>
      <div className="infomation-div" style={{ height: "100%" }}>
        <p className="mt-0 tranch-head mb-15">Financials</p>
        <div className="mt-15 table-container">
          <Table
            columns={financialColumns}
            dataSource={financialDataForTable}
            className="trache-table outstanding-pay-table"
          />
        </div>
      </div>
    </>
  );
};

export default AssetsInfo;
