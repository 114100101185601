import React from "react";
import CommonFilter from "./CommonFilter";

const FilterComponent = ({ trancheCountry, newTranchList }) => {
  return (
    <div className="filter-desktop-comp">
      <CommonFilter trancheCountry={trancheCountry} newTranchList={newTranchList} />
    </div>
  );
};

export default FilterComponent;
