import React, { useEffect, useState } from "react";

import dayjs from "dayjs";

import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Radio,
  Row,
  Space,
} from "antd";
import Hyphen from "../../../Assets/Images/Hyphen.svg";

import { setAccStatementFilter } from "../../../Redux/Action/Dashboards";
import { useDispatch } from "react-redux";
import "./style.css";
import moment from "moment/moment";

const AccountStatementCommonFilter = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(null);
  const [showSubCheckboxes, setShowSubCheckboxes] = useState(false);
  const [filterData, setFilterData] = useState({
    postingDateFrom: "",
    postingDateTo: "",
    currencyCode: "USD",
    txTypeList: [],
    txSubType: [],
  });

  useEffect(() => {
    const updatedData = { ...filterData, txSubType: [] };
    setFilterData({ ...filterData, txSubType: [] });
    setAccStatementFilter({ ...filterData, txSubType: [] }, dispatch);
    localStorage.setItem("accStatementFilterData", JSON.stringify(updatedData));
  }, [showSubCheckboxes]);

  const handleMainCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setShowSubCheckboxes(isChecked);
    localStorage.setItem("isRepaymentActive", isChecked);
    setFilterData({ ...filterData, txSubType: [] });
  };

  const handleSubCheckboxChange = (e) => {
    setFilterData({ ...filterData, txSubType: e });
    setAccStatementFilter({ ...filterData, txSubType: e }, dispatch);
    let updatedFilterData = { ...filterData, txSubType: e };

    localStorage.setItem(
      "accStatementFilterData",
      JSON.stringify(updatedFilterData)
    );
  };

  useEffect(() => {
    const storedActiveTab = localStorage.getItem("activeTab");
    if (storedActiveTab) {
      setActiveTab(storedActiveTab);
    }
    const isRepayment = localStorage.getItem("isRepaymentActive");
    if (isRepayment) {
      setShowSubCheckboxes(isRepayment);
    }
  }, []);

  useEffect(() => {
    setFilterData(JSON.parse(localStorage.getItem("accStatementFilterData")));
  }, [filterData]);

  const handleChangeCurrency = async (e) => {
    const newCurrencyCode =
      e?.target?.value === filterData.currencyCode ? "" : e.target.value;
    setFilterData({ ...filterData, currencyCode: newCurrencyCode });
    setAccStatementFilter(
      { ...filterData, currencyCode: newCurrencyCode },
      dispatch
    );
    let updatedFilterData = {
      ...filterData,
      currencyCode: newCurrencyCode,
    };
    localStorage.setItem(
      "accStatementFilterData",
      JSON.stringify(updatedFilterData)
    );
  };

  const handleTransactionChange = (e) => {
    setFilterData({ ...filterData, txTypeList: e });
    setAccStatementFilter({ ...filterData, txTypeList: e }, dispatch);
    let updatedFilterData = { ...filterData, txTypeList: e };
    localStorage.setItem(
      "accStatementFilterData",
      JSON.stringify(updatedFilterData)
    );
  };

  const handleChangePostingDateFrom = async (date, dateString) => {
    const isoDate = date
      ? moment(dateString, "DD/MM/YYYY").utc(true).toISOString()
      : null;
    setActiveTab(null);
    localStorage.removeItem("activeTab");
    const updatedFilterData = { ...filterData, postingDateFrom: isoDate };
    const updatedCookieFilterData = {
      ...filterData,
      postingDateFrom: isoDate,
    };
    setFilterData(updatedFilterData);
    // setcookieFilterData(updatedCookieFilterData);
    localStorage.setItem(
      "accStatementFilterData",
      JSON.stringify(updatedCookieFilterData)
    );
    await setAccStatementFilter(updatedFilterData, dispatch);
  };

  const handleChangePostingDateTo = async (date, dateString) => {
    const isoDate = date
      ? moment(dateString, "DD/MM/YYYY").utc(true).toISOString()
      : null;
    setActiveTab(null);
    localStorage.removeItem("activeTab");
    const updatedFilterDataTo = { ...filterData, postingDateTo: isoDate };
    const updatedCookieFilterData = {
      ...filterData,
      postingDateTo: isoDate,
    };
    setFilterData(updatedFilterDataTo);
    // setcookieFilterData(updatedCookieFilterData);
    localStorage.setItem(
      "accStatementFilterData",
      JSON.stringify(updatedCookieFilterData)
    );

    await setAccStatementFilter(updatedFilterDataTo, dispatch);
  };

  const getDateRange = (val) => {
    let fromDate, toDate;

    switch (val) {
      case "today":
        fromDate = toDate = moment().startOf("day").utc(true);
        break;
      case "week":
        fromDate = moment().startOf("isoWeek").utc(true);
        toDate = moment().endOf("isoWeek").utc(true);
        break;
      case "month":
        fromDate = moment().startOf("month").utc(true);
        toDate = moment().endOf("month").utc(true);
        break;
      default:
        fromDate = toDate = moment();
    }
    let updatedFilterData = {
      ...filterData,
      postingDateFrom: fromDate.toISOString(),
      postingDateTo: toDate.toISOString(),
    };
    localStorage.setItem(
      "accStatementFilterData",
      JSON.stringify(updatedFilterData)
    );
    return {
      from: fromDate.toISOString(),
      to: toDate.toISOString(),
    };
  };

  const handleTiming = async (val) => {
    if (activeTab === val) {
      setActiveTab(null);
      setFilterData({ ...filterData, postingDateFrom: "", postingDateTo: "" });
      const storedData = JSON.parse(
        localStorage.getItem("accStatementFilterData")
      );

      if (storedData) {
        const updatedData = { ...storedData };
        delete updatedData["postingDateFrom"];
        delete updatedData["postingDateTo"];
        localStorage.setItem(
          "accStatementFilterData",
          JSON.stringify(updatedData)
        );
      }
      await setAccStatementFilter(
        {
          ...filterData,
          postingDateFrom: "",
          postingDateTo: "",
        },
        dispatch
      );
      localStorage.removeItem("activeTab");
    } else {
      setActiveTab(val);
      localStorage.setItem("activeTab", val); // Store active tab in localStorage
      const { from, to } = getDateRange(val);
      setFilterData({
        ...filterData,
        postingDateFrom: from,
        postingDateTo: to,
      });
      await setAccStatementFilter(
        {
          ...filterData,
          postingDateFrom: from,
          postingDateTo: to,
        },
        dispatch
      );
      let updatedFilterData = {
        ...filterData,
        postingDateFrom: from,
        postingDateTo: to,
      };
      localStorage.setItem(
        "accStatementFilterData",
        JSON.stringify(updatedFilterData)
      );
    }
  };

  const disabledDate = (current) => {
    return current && current > dayjs().endOf("day");
  };

  return (
    <div>
      <Row className="filter-modal">
        <Col sx={24} className="filter-modal-head">
          Show Filtres
        </Col>
        <Divider className="filter-modal-divider" />
      </Row>
      <div className="filter-media-input-div">
        <p className="filter-subtitle mt-0">Date</p>
        <div className="filter-date-div">
          <Row className="currency-filter-div">
            <Button
              onClick={() => handleTiming("today")}
              className={activeTab === "today" ? "active-tab" : null}
            >
              Today
            </Button>
            <Button
              onClick={() => handleTiming("week")}
              className={activeTab === "week" ? "active-tab" : null}
            >
              This week
            </Button>
            <Button
              onClick={() => handleTiming("month")}
              className={activeTab === "month" ? "active-tab" : null}
            >
              This month
            </Button>
          </Row>
        </div>
        <Row gutter={10}>
          <Col xs={11} className="gutter-row">
            <DatePicker
              onChange={handleChangePostingDateFrom}
              placeholder="From"
              className="filter-date-picker"
              value={
                filterData?.postingDateFrom &&
                filterData?.postingDateFrom !== "null"
                  ? dayjs(filterData?.postingDateFrom)
                  : filterData?.postingDateFrom
                  ? dayjs(filterData.postingDateFrom)
                  : null
              }
              format="DD/MM/YYYY"
              disabledDate={disabledDate}
            />
          </Col>
          <Col xs={2} className="gutter-row" style={{ marginTop: 5 }}>
            <img src={Hyphen} alt="Hyphen" />
          </Col>
          <Col xs={11} className="gutter-row">
            <DatePicker
              onChange={handleChangePostingDateTo}
              format="DD/MM/YYYY"
              placeholder="to"
              className="filter-date-picker"
              value={
                filterData?.postingDateTo &&
                filterData?.postingDateTo !== "null"
                  ? dayjs(filterData?.postingDateTo)
                  : filterData?.postingDateTo
                  ? dayjs(filterData.postingDateTo)
                  : null
              }
              disabledDate={disabledDate}
            />
          </Col>
        </Row>
        <p className="filter-subtitle mt-24 ">Currency</p>
        <Radio.Group
          className="acc-filter-radio"
          onChange={handleChangeCurrency}
          value={
            filterData?.currencyCode && filterData?.currencyCode !== "null"
              ? filterData?.currencyCode
              : filterData?.currencyCode
          }
        >
          <Space direction="vertical">
            <Radio value="USD">USD</Radio>
            <Radio value="EUR">EUR</Radio>
            <Radio value="SGD">SGD</Radio>
          </Space>
        </Radio.Group>
        <p className="filter-subtitle mt-24">Transaction type</p>
        <Checkbox.Group
          className="acc-filter-checkbox"
          onChange={handleTransactionChange}
          checked={
            filterData?.txTypeList && filterData?.txTypeList?.length > 0
              ? filterData?.txTypeList
              : []
          }
          value={
            filterData?.txTypeList && filterData?.txTypeList?.length > 0
              ? filterData?.txTypeList
              : []
          }
        >
          <Row>
            <Col span={24}>
              <Checkbox className="checkbox-kilde" value="DEPOSIT">
                Deposit
              </Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox className="checkbox-kilde" value="WITHDRAWAL_REQUEST">
                Withdrawal request
              </Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox className="checkbox-kilde" value="WITHDRAWAL">
                Withdrawal
              </Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox className="checkbox-kilde" value="SUBSCRIPTION">
                Subscription
              </Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox className="checkbox-kilde" value="SETTLEMENT">
                Settlement
              </Checkbox>
            </Col>
            <Col span={24}>
              <Checkbox
                className="checkbox-kilde"
                value={"REPAYMENT"}
                onClick={handleMainCheckboxChange}
              >
                Repayment
              </Checkbox>
            </Col>
          </Row>
        </Checkbox.Group>
        {showSubCheckboxes && (
          <Col span={24} style={{ marginLeft: "14px" }}>
            <Checkbox.Group
              className="sub-checkbox-group"
              value={
                filterData?.txSubType && filterData?.txSubType?.length > 0
                  ? filterData?.txSubType
                  : []
              }
              onChange={handleSubCheckboxChange}
              checked={
                filterData?.txSubType && filterData?.txSubType?.length > 0
                  ? filterData?.txSubType
                  : []
              }
            >
              <Checkbox className="radio-kilde" value="INTEREST">
                Interest
              </Checkbox>
              <Checkbox className="radio-kilde" value="PRINCIPAL">
                Principal
              </Checkbox>
              <Checkbox className="radio-kilde" value="WHT">
                WHT
              </Checkbox>
            </Checkbox.Group>
          </Col>
        )}
      </div>
    </div>
  );
};
export default AccountStatementCommonFilter;
