import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import DeviceMobile from "../../Assets/Images/IconSet2fa.svg";
import ROUTES from "../../Config/Routes.js";
import { getUser, twoFaAuth, twoFaInit } from "../../Apis/UserApi.js";

import "./style.css";
import Cookies from "js-cookie";
import AuthLayout from "../../Layouts/BlankHeaderLayout/AuthLayout.jsx";
import OtpInput from "react18-input-otp";
import { Link } from "react-router-dom";
import ButtonDefault from "../../Components/ButtonDefault/ButtonDefault.jsx";
import {
  redirectAfterLoginAnd2FA,
  redirectToVue,
} from "../../Utils/Helpers.js";
import GlobalVariabels from "../../Utils/GlobalVariabels.js";
import { getSystemId, statusCheck } from "../../Apis/InvestorApi.js";
import { setStatusCheckResponse } from "../../Redux/Action/KycIndividual.js";
import { Redirection } from "../LoginPage/Redirection.jsx";
import {
  showMessageWithCloseIcon,
  showMessageWithCloseIconError,
} from "../../Utils/Reusables.js";
import { identify } from "../../Utils/Analytics.js";
import totpIcon from "../../Assets/Images/IconSet_totp.svg";
import { GetBankAccountApi } from "../../Apis/WalletApi.js";
import { setAccountDetails } from "../../Redux/Action/Wallet.js";
import JivoChat from "../../Layouts/BlankHeaderLayout/JivoChat.js";

const TwoFAPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [otp, setOtp] = useState("");
  const [loader, setLoader] = useState(false);
  const [code, setCode] = useState("");
  const [time, setTime] = useState(59);
  const [isTimerRunning, setIsTimerRunning] = useState(true);
  const [secFactorAuth, setSecFactorAuth] = useState("");
  const [mobile, setMobile] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  let currentEnv = GlobalVariabels.NODE_ENV;

  useEffect(() => {
    twoFaInit()
      .then((res) => {
        if (res?.length === 0) {
          showMessageWithCloseIconError("Something went wrong, Try again!");
        } else {
          setSecFactorAuth(res?.type);
          setCode(res?.authenticationCode);
          setMobile(res?.mobilePhone);
          if (res?.type === "TOTP") {
            showMessageWithCloseIcon(
              "Please check your authentication app for current one time password"
            );
          } else {
            showMessageWithCloseIcon(
              "We've sent an OTP to your mobile number. Please check your messages."
            );
          }
        }
      })
      .catch((err) => {
        console.log("2Fa api error", err);
      });
  }, []);

  const resendInit = async () => {
    await twoFaInit()
      .then((res) => {
        if (res?.length === 0) {
          showMessageWithCloseIconError("Something went wrong, Try again!");
        } else {
          setCode(res?.authenticationCode);
          setMobile(res?.mobilePhone);
          setIsTimerRunning(true);
          setTime(59);
          showMessageWithCloseIcon(
            "We've sent an OTP to your mobile number. Please check your messages."
          );
        }
      })
      .catch((err) => {
        console.log("2Fa api error", err);
      });
  };

  const getBankAccountNo = async () => {
    try {
      const response = await GetBankAccountApi();
      if (response) {
        await setAccountDetails(response, dispatch);
        return response;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      return null;
    }
  };

  const handleAuthentication = async () => {
    setLoader(true);
    if (otp !== "") {
      const requestBody = {
        code: otp,
      };
      const response = await twoFaAuth(requestBody);
      if (response?.token) {
        // window?.dataLayer?.push({
        //   event: "authenticationTwoFactorConfirm",
        //   user_id: user?.number,
        // });
        Cookies.set("auth_inv_token", response.token);
        getUser()
          .then(async (profileResponse) => {
            identify(profileResponse);
            Cookies.set("user", JSON.stringify(profileResponse));
            if (profileResponse?.investorStatus === "REJECTED") {
              return navigate(ROUTES.KILDE_REJECTED_USER);
            }
            let regtankStatus;
            if (
              profileResponse?.vwoFeatures?.identityVerificationSystem
                ?.idvSystemToUse === "regtank"
            ) {
              const getSystId = await getSystemId();
              if (
                getSystId?.systemId !== null &&
                getSystId?.systemId !== undefined &&
                Object.keys(getSystId)?.length > 0
              ) {
                regtankStatus = await statusCheck({
                  email: profileResponse?.email,
                  systemId: getSystId?.systemId,
                });
                await setStatusCheckResponse(regtankStatus, dispatch);
                Cookies.set("systemId", getSystId?.systemId);
              }
            }
            if (
              profileResponse?.vwoFeatures.redirectApp?.appToRedirect === "vue"
            ) {
              redirectToVue(
                profileResponse?.vwoFeatures.redirectApp?.appToRedirect
              );
              setLoader(false);
            } else {
              await getBankAccountNo();
              const cookies = document.cookie
                .split("; ")
                .reduce((acc, cookie) => {
                  const [name, value] = cookie.split("=");
                  acc[name] = value;
                  return acc;
                }, {});

              const redirectUrl = cookies.redirectTrancheUrl;
              if (redirectUrl) {
                return redirectAfterLoginAnd2FA(navigate);
              } else {
                Redirection(
                  setLoader,
                  profileResponse,
                  regtankStatus,
                  dispatch,
                  navigate,
                  profileResponse?.vwoFeatures?.redirectApp
                );
              }
            }
          })
          .catch((error) => {
            console.log("profile err", error);
            setLoader(false);
          });
      } else {
        setLoader(false);
      }
    } else {
      setLoader(false);
      showMessageWithCloseIconError("Please enter OTP!");
    }
  };

  useEffect(() => {
    const intervalTimer = setInterval(() => {
      if (isTimerRunning) {
        setTime((prevTime) => {
          if (prevTime <= 1) {
            clearInterval(intervalTimer);
            setIsTimerRunning(false);
          }
          return prevTime - 1;
        });
      }
    }, 1000);

    return () => clearInterval(intervalTimer);
  }, [isTimerRunning]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const inputStyle = {
    borderRadius: "var(--12, 12px)",
    border: "1px solid var(--black-10, rgba(26, 32, 44, 0.10))",
    background: "var(--white-80, rgba(255, 255, 255, 0.80))",
    width: windowWidth <= 380 ? "35px" : "40px",
    marginLeft: "4px",
    marginRight: "4px",
    height: windowWidth <= 380 ? "35px" : "40px",
    fontSize: "14px",
    fontWeight: "400",
    padding: "var(--4, 4px) var(--12, 12px)",
    color: "var(--kilde-blue)",
  };

  return (
    <AuthLayout>
      <div className="sb-onboarding-form-container">
        <div className="sb-flex-column-item-center mb-28">
          {secFactorAuth === "TOTP" ? (
            <img src={totpIcon} alt="totp" className="kl-mobile" />
          ) : (
            <img src={DeviceMobile} alt="sms" className="kl-mobile" />
          )}
        </div>
        <div>
          <p className="kl-title  m-0">Two-Factor Authentication</p>

          <p className="kl-subtitle mt-8 mb-28">
            {secFactorAuth === "TOTP"
              ? "Enter current one time password from your Google Authentication app."
              : `Enter the verification code we send to ${mobile}`}
          </p>
        </div>

        <div className="sb-otp-input-div mb-28">
          <OtpInput
            value={otp}
            onChange={setOtp}
            inputStyle={inputStyle}
            numInputs={6}
            renderSeparator={<span></span>}
            isInputNum={true}
            inputProps={{ type: "number", inputMode: "numeric" }}
            renderInput={(props) => (
              <input {...props} type="number" inputMode="numeric" />
            )}
            shouldAutoFocus
          />
        </div>

        {secFactorAuth === "SMS" && isTimerRunning === true && (
          <div className="kd-resend">
            <p className="mb-16">
              Resend code in{" "}
              <span id="timer">{isTimerRunning ? `(${time}s)` : ""}</span>{" "}
            </p>
          </div>
        )}
        {secFactorAuth === "SMS" && isTimerRunning === false && (
          <p
            className="sb-twofa-link mb-16"
            onClick={isTimerRunning ? null : resendInit}
          >
            <Link> Resend Code</Link>
          </p>
        )}

        <div className="sb-TwoFa-actions m-0">
          {secFactorAuth === "SMS" && currentEnv === "DEV" && (
            <div>
              <p style={{ textAlign: "right", color: "#ddd", margin: "0px" }}>
                Authentication Code: {code}
              </p>
            </div>
          )}
          <ButtonDefault
            title="Authenticate"
            block={true}
            loading={loader}
            onClick={handleAuthentication}
            style={{ marginTop: "12px" }}
          />
        </div>

        <p className="kl-subtitle mt-12">
          Didn’t get the code ?{"  "}
          <Link
            style={{ fontSize: 14, color: "var(--kilde-blue)" }}
            to="tel:+65 695 077 68"
          >
            Call Us
          </Link>
        </p>
      </div>
      {/* <SupportChatButton /> */}
      <JivoChat user={user} />
    </AuthLayout>
  );
};

export default TwoFAPage;
