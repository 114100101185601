/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from "react";
import { Col, Divider, Modal, Row } from "antd";
import { LOGO_LINK, PDF, TRUST_PILOT } from "../../Utils/Constant";
import KildeLogo from "../../Assets/Images/kilde-logo-white.svg";
import cookiepolicy from "../../Assets/Pdf/cookie policy.pdf";
import "./style.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import JivoChat from "./JivoChat";
import TermsOfUse from "../../Assets/Pdf/Terms of Use.pdf";
import TrustPilotWidget from "./TrustPilotWidget";

const Footer = () => {
  const [pdfModalVisible, setPdfModalVisible] = useState(false);
  const user = useSelector((state) => state?.user);

  const openPdfModal = () => {
    setPdfModalVisible(true);
  };

  const closePdfModal = () => {
    setPdfModalVisible(false);
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "//embed.typeform.com/next/embed.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      <div className="kd-footer-div">
        <div>
          <div className="logo-div kd-footer-sub-logo-sb">
            <div>
              <Link to={LOGO_LINK}>
                <img src={KildeLogo} alt="kildelogo" className="footer-logo" />
              </Link>
            </div>
            <div className="review-specific-screen">
              <div className="review-footer-main-div">
                <TrustPilotWidget />
                <div>
                  <Link
                    to={TRUST_PILOT}
                    className="review-text"
                    target="_blank"
                  >
                    Give Us a Review on Trustpilot
                  </Link>
                </div>
              </div>
            </div>
            <div className="footer-media-title-div">
              <div className="review-footer-main-div mt-48 mb-16">
                <TrustPilotWidget />
              </div>
              <div className="mb-16">
                <Link to={TRUST_PILOT} className="review-text" target="_blank">
                  Give Us a Review on Trustpilot
                </Link>
              </div>
              {/* <p className="footer-media-title mt-4 mb-0">© 2025 Kilde</p> */}
            </div>
            <div className="media-footer">
              <div className="dashboard-footer-link">
                <div>
                  <a href={TermsOfUse}>
                    Qualification Guidelines for Individuals
                  </a>
                </div>
                <div>
                  <a href={TermsOfUse}>Terms of Use</a>
                </div>
                <div>
                  <Link
                    to={cookiepolicy}
                    onClick={(e) => {
                      e.preventDefault();
                      openPdfModal();
                    }}
                  >
                    Cookie Policy
                  </Link>
                </div>
                <div>
                  <Link to={PDF.PRIVACY_POLICY}>Privacy Policy</Link>
                </div>
                <div>
                  <Link to={PDF.COMPLAINT_POLICY}>Complaints policy</Link>
                </div>
              </div>

              <div className="dashboard-footer-bottom-media-link">
                <div className="footer-with-border">
                  <p className="footer-title m-0">
                    20 McCallum Street #19-01, Tokio Marine Centre, Singapore,
                    069046
                  </p>
                </div>
                <div className="footer-with-border">
                  <p className="footer-title m-0">
                    <a href="tel:+65 695 077 68"> +65 695 077 68</a>
                  </p>
                </div>
                <div className="footer-with-border">
                  <p className="footer-title m-0">
                    <a href="mailto:sales@kilde.sg">sales@kilde.sg</a>
                  </p>
                </div>
                <div className="footer-with-border">
                  <p className="footer-title m-0">
                    <p className="mt-4 mb-16">© 2025 Kilde</p>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <Row className="logo-review-div kd-footer-sub">
            <div className="review-footer-main-div media-review">
              <TrustPilotWidget />
              <div className="mb-10">
                <Link to={TRUST_PILOT} className="review-text" target="_blank">
                  Give Us a Review on Trustpilot
                </Link>
              </div>
            </div>
            <div className="specific-screen-div mb-16">
              {" "}
              <div className="dashboard-footer-link">
                <div>
                  <a href={TermsOfUse}>
                    Qualification Guidelines for Individuals
                  </a>
                </div>
                <div>
                  <a href={TermsOfUse}>Terms of Use</a>
                </div>
                <div>
                  <Link
                    to={cookiepolicy}
                    onClick={(e) => {
                      e.preventDefault();
                      openPdfModal();
                    }}
                  >
                    Cookie Policy
                  </Link>
                </div>
                <div>
                  <Link to={PDF.PRIVACY_POLICY}>Privacy Policy</Link>
                </div>
                <div>
                  <Link to={PDF.COMPLAINT_POLICY}>Complaints policy</Link>
                </div>
              </div>
            </div>

            <div className="footer-child-link-div">
              <div className="dashboard-footer-bottom-link">
                <div className="footer-with-border">
                  <p className="footer-title m-0">
                    20 McCallum Street #19-01, Tokio Marine Centre, Singapore,
                    069046
                  </p>
                </div>
                <div className="footer-with-border">
                  <p className="footer-title m-0">
                    <a href="tel:+65 695 077 68"> +65 695 077 68</a>
                  </p>
                </div>
                <div className="footer-with-border">
                  <p className="footer-title m-0">
                    <a href="mailto:sales@kilde.sg">sales@kilde.sg</a>
                  </p>
                </div>
              </div>
              <div>
                <p className="footer-main-title m-0">© 2025 Kilde</p>
              </div>
            </div>
          </Row>
          <div className="footer-divider-div">
            <Divider plain />
          </div>
          <Row className="kd-footer-sub-bottom">
            <Col xs={24} sm={24} md={20} lg={15}>
              <p className="footer-botton-title m-0">
                Disclaimer: KILDE PTE LTD is incorporated in Singapore under the
                registration no. 201929587K and holds a Capital Markets Services
                licence (CMS101016) issued by the Monetary Authority of
                Singapore to deal in capital markets products under the
                Securities and Futures Act (Cap. 289) and an Exempted Financial
                Advisor License under the Financial Adviser Act. The information
                on this website is intended for “institutional investors” and
                “accredited investors”, in accordance with the Securities and
                Futures Act (Cap. 289) of Singapore. Information provided on
                this website does not constitute an offer, invitation to invest,
                solicitation or advice on buying or selling financial
                instruments and other capital market products.
              </p>
            </Col>
          </Row>
        </div>
      </div>
      <Modal
        className="sb-pdf-modal"
        centered
        open={pdfModalVisible}
        onCancel={closePdfModal}
        width={1000}
        footer={null}
      >
        <iframe
          className="mt-20"
          src={cookiepolicy}
          width="100%"
          height="500px"
          title="PDF Viewer"
        />
      </Modal>
      {/* {user?.vwoFeatures?.goBackToVue?.goBackToVueButtonHide === false &&
      "/" + routeName !== ROUTES.INDIVIDUAL_VERIFICATION &&
      "/" + routeName !== ROUTES.VERIFICATION &&
      "/" + routeName !== ROUTES.ORGANIZATION_VERIFICATION ? (
        <Button
          className="dashboard-go-back-btn"
          onClick={handleRedirectToVue}
          id="go-back-to-vue"
        >
          <img src={arrowLeft} alt="arrowleft" />
          <span id="go-back-to-vue">Back To The Old Look</span>
        </Button>
      ) : null} */}

      {/* <SupportChatButton /> */}
      <JivoChat user={user} />
    </div>
  );
};

export default Footer;
