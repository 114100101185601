/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import ReactLoading from "react-loading";
import DashboardLayout from "../../../Layouts/DashboardLayout/DashboardLayout";
import { Breadcrumb, Button, Col, Layout, Row, Table, Tooltip } from "antd";

import Down_blue_arrow from "../../../Assets/Images/Icons/down_blue_arrow.svg";

import "./style.css";
import Plus_Icon from "../../../Assets/Images/Plus_icon.svg";
import Close_Icon from "../../../Assets/Images/SVGs/close_gray.svg";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import TranchInvestInfo from "./TranchInvestInfo";
import StartInvesting from "./StartInvesting";
import InvestDetails from "./InvestDetails";
import BondDescription from "./BondDescription";
import AssetsInfo from "./AssetsInfo";
import Document from "./Document";
import OutStandingPayment from "./OutStandingPayment";
import StartCapitalCallRequest from "./StartCapitalCallRequest";
import CapitalCallRequestLists from "./CapitalCallRequestList";

import { CapitalCallList, InvestTranche } from "../../../Apis/DashboardApi";
import { britishFormatDate, getCountries } from "../../../Utils/Helpers";
import GlobalVariabels from "../../../Utils/GlobalVariabels";
import ROUTES from "../../../Config/Routes";
import { useDispatch, useSelector } from "react-redux";
import FinishOnboarding from "../Investment/FinishOnboarding";
import {
  setCapitalRequests,
  setTrancheResponse,
} from "../../../Redux/Action/Investor";
import { ErrorResponse } from "../../../Utils/ErrorResponse";
import { getUser } from "../../../Apis/UserApi";
import { setUserDetails } from "../../../Redux/Action/User";
import { formatCurrency, getCountryNameByCode } from "../../../Utils/Reusables";
import ReservationDetails from "./ReservationDetails";

const { Content } = Layout;

const Borrower = () => {
  const [activeSection, setActiveSection] = useState("overview");
  const sectionsRef = useRef({});
  const infoDivRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { slug } = useParams();
  const TrancheRes = useSelector((state) => state?.investor?.tranche);
  const [loader, setLoader] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [expandedOverview, setExpandedOverview] = useState(false);
  const user = useSelector((state) => state.user);
  const [capitalRequestLoading, setCapitalRequestLoading] = useState(false);
  const countryList = getCountries();
  const accountNo = useSelector(
    (state) => state?.wallet?.bankAccount?.bankAccounts
  );
  const [renderComponent, setRenderComponent] = useState(false);
  const [couponAmount, setCouponAmount] = useState();
  const [openstateModal, setOpenstateModal] = useState(false);
  const modalRef = useRef(null);
  const fixedComponentRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target) &&
        fixedComponentRef.current &&
        !fixedComponentRef.current.contains(event.target)
      ) {
        setOpenstateModal(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleScroll = () => {
    const sectionNames = Object.keys(sectionsRef.current);
    let currentActiveSection = activeSection;

    sectionNames.forEach((section) => {
      const sectionEl = sectionsRef.current[section];
      if (sectionEl) {
        const rect = sectionEl.getBoundingClientRect();
        if (rect.top >= 0 && rect.top < window.innerHeight / 2) {
          currentActiveSection = section;
        }
      }
    });

    if (currentActiveSection !== activeSection) {
      setActiveSection(currentActiveSection);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [activeSection]);

  useEffect(() => {
    getUserDetails();
  }, []);

  useEffect(() => {
    if (location?.state && location?.state?.scrollState) {
      if (TrancheRes) {
        const timeout = setTimeout(() => {
          if (infoDivRef.current) {
            infoDivRef.current.scrollIntoView({
              behavior: "smooth",
              block: "center",
            });
          }
        }, 100);
        return () => clearTimeout(timeout);
      }
    }
  }, [TrancheRes, location]);

  const isFutureDate = (dateString) => {
    const givenDate = new Date(dateString);
    const currentDate = new Date();
    return givenDate > currentDate;
  };

  useEffect(() => {
    if (
      TrancheRes?.tranche?.subscriptionEnabled === false &&
      !isFutureDate(TrancheRes?.tranche?.issueDate) &&
      location?.state?.amount !== undefined
    ) {
      navigate(ROUTES.TRANCH_LISTING);
    } else if (location?.state?.amount >= TrancheRes?.tranche?.nominalValue) {
      setCouponAmount(location?.state?.amount);
    }
  }, [location?.state?.amount]);

  const getUserDetails = async () => {
    try {
      const response = await getUser();
      if (response) {
        await setUserDetails(response, dispatch);
        return response;
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      return null;
    }
  };

  useEffect(() => {
    setLoader(true);
    const requestBody = {
      trancheUuid: slug,
    };
    InvestTranche(requestBody).then(async (tracheRes) => {
      await setTrancheResponse(tracheRes, dispatch);
      setLoader(false);
    });
  }, [renderComponent, location]);

  const capitalCallColumns = [
    {
      title: "Next Capital Call Dates",
      dataIndex: "NextCapitalCallDates",
    },
    {
      title: "Submit Notice by",
      dataIndex: "SubmitNoticeBy",
    },
  ];

  const capitalCallData = TrancheRes?.tranche?.capitalCallDates?.map(
    (data, index) => ({
      key: index,
      NextCapitalCallDates: britishFormatDate(data.nextCapitalCallDate),
      SubmitNoticeBy: britishFormatDate(data.nextCapitalCallRequestDeadline),
    })
  );

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  const toggleLess = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    const requestBody = {
      trancheUuid: slug,
    };
    if (TrancheRes?.investment?.debentureCount > 0) {
      getCapitalCallRequestList(requestBody);
    }
  }, [TrancheRes?.investment?.debentureCount]);

  const getCapitalCallRequestList = (requestBody) => {
    setCapitalRequestLoading(true);
    CapitalCallList(requestBody)
      .then(async (capitaRequestlist) => {
        if (Object.keys(capitaRequestlist)?.length > 0) {
          await setCapitalRequests(capitaRequestlist, dispatch);
          setCapitalRequestLoading(false);
        } else {
          setCapitalRequestLoading(false);
        }
      })
      .catch((error) => {
        ErrorResponse(error?.code);
        setCapitalRequestLoading(false);
      });
  };

  const handleScrollToSection = (section) => {
    if (sectionsRef.current[section]) {
      const element = sectionsRef.current[section];
      const offsetTop =
        element.getBoundingClientRect().top + window.scrollY - 80;
      window.scrollTo({
        top: offsetTop,
        behavior: "smooth",
      });
    }
  };

  const sections = [
    { key: "overview", label: "Overview" },
    { key: "invest", label: "Bond" },
    {
      key: "financial",
      label: "Financials",
      condition: TrancheRes?.tranche?.details?.keyFinancialsCsv,
    },
    { key: "business-description", label: "Business" },
    {
      key: "repayments",
      label: "Repayment sched.",
      condition:
        TrancheRes?.tranche?.paymentScheduleSummary?.payments?.length > 0,
    },
    {
      key: "capital",
      label: "Early redemption",
      condition: TrancheRes?.tranche?.capitalCallEnabled === true,
    },
  ];

  return (
    <div className={`app-container ${openstateModal ? "modal-open" : ""}`}>
      <DashboardLayout>
        {loader === false ? (
          <Content className="borrower-page-div">
            <div className="borrower-navigation-div">
              <div className="borrower-navigation-sub-div">
                {sections.map(
                  ({ key, label, condition = true }) =>
                    condition && (
                      <p
                        key={key}
                        className={`notification-title mt-12 cursor-pointer ${
                          activeSection === key ? "active-borrower-tab" : ""
                        }`}
                        onClick={() => handleScrollToSection(key)}
                      >
                        {label}
                      </p>
                    )
                )}
              </div>
            </div>
            {user?.investorStatus !== "ACTIVE" ||
            (user?.investorStatus === "ACTIVE" && accountNo?.length <= 0) ||
            (user?.secondFactorAuth === null &&
              user?.twoFaCheckEnabled === true) ? (
              <div className="mb-24">
                <FinishOnboarding />
              </div>
            ) : null}
            <Breadcrumb>
              <Breadcrumb.Item
                onClick={() => navigate(ROUTES.TRANCH_LISTING)}
                className="cursor-pointer"
              >
                Invest
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                {TrancheRes?.tranche?.details?.title}
              </Breadcrumb.Item>
            </Breadcrumb>
            <Row className="media-tranche-head">
              <Col sx={24} sm={24} className="mt-10 mb-8">
                {TrancheRes?.borrower?.companyLogo ? (
                  <div className="company-logo-div">
                    <img
                      src={`${GlobalVariabels.VIEW_IMG}/${TrancheRes?.borrower?.companyLogo}`}
                      alt="borrower_icon"
                      id="company-logo-div"
                    />
                  </div>
                ) : null}
              </Col>
              <Col xs={24} md={24}>
                <p className="borrower-id-text mb-0  mt-0">
                  {TrancheRes?.tranche?.trancheNumber}
                </p>
                <h2 className="mt-0 mb-0 borrower-company-title">
                  {TrancheRes?.tranche?.details?.title}
                </h2>
              </Col>
            </Row>
            <div className="borrower-logo-div desktop-tranche-head">
              <div>
                <p className="borrower-id-text mb-0 mt-10">
                  {TrancheRes?.tranche?.trancheNumber}
                </p>
                <h2 className="mt-0 mb-0 borrower-company-title">
                  {TrancheRes?.tranche?.details?.title}
                </h2>
              </div>
              {TrancheRes?.borrower?.companyLogo ? (
                <div className="company-logo-div">
                  <img
                    src={`${GlobalVariabels.VIEW_IMG}/${TrancheRes?.borrower?.companyLogo}`}
                    alt="borrower_icon"
                  />
                </div>
              ) : null}
            </div>
            <Row
              className="mt-24 mb-24 media-borrower-b-row overview"
              ref={(el) => (sectionsRef.current["overview"] = el)}
            >
              <TranchInvestInfo TrancheRes={TrancheRes} />
            </Row>

            <Row
              className={
                window.innerWidth <= 576
                  ? "mt-16 mb-24 media-borrower-b-row overview"
                  : "mt-24 mb-24 media-borrower-b-row overview"
              }
            >
              <Col className="gutter-row" lg={24} md={24} sm={24} xs={24}>
                <Row
                  className="gutter-row infomation-div"
                  style={{ gap: "24px" }}
                >
                  {TrancheRes?.tranche?.details?.summary?.shortDescription && (
                    <Col sm={24} md={12} lg={12} style={{ flex: 1 }}>
                      <p className="m-0 tranch-head">Overview</p>
                      <p className="business-dec-borrower mb-0">
                        <>
                          {expandedOverview ? (
                            TrancheRes?.tranche?.details?.summary?.shortDescription
                              .split("\n\n")
                              .map((paragraph, index) => (
                                <p key={index} className="mb-0">
                                  {paragraph
                                    .split("\n")
                                    .map((line, lineIndex) => (
                                      <React.Fragment key={lineIndex}>
                                        {line}
                                        {lineIndex <
                                          paragraph.split("\n").length - 1 && (
                                          <br />
                                        )}
                                      </React.Fragment>
                                    ))}
                                </p>
                              ))
                          ) : (
                            <p className="mb-0">
                              {`${TrancheRes?.tranche?.details?.summary?.shortDescription.slice(
                                0,
                                500
                              )}`}
                              {TrancheRes?.tranche?.details?.summary
                                ?.shortDescription.length > 500 &&
                                !expandedOverview &&
                                "..."}
                            </p>
                          )}
                        </>
                      </p>
                      {TrancheRes?.tranche?.details?.summary?.shortDescription
                        .length > 500 ? (
                        expandedOverview ? (
                          <Button
                            onClick={() =>
                              setExpandedOverview(!expandedOverview)
                            }
                            className="read-more-btn"
                          >
                            Read Less{" "}
                            <img
                              className="ml-4"
                              src={Down_blue_arrow}
                              alt="Down_blue_arrow"
                            />
                          </Button>
                        ) : (
                          <Button
                            onClick={() =>
                              setExpandedOverview(!expandedOverview)
                            }
                            className="read-more-btn"
                          >
                            Read More{" "}
                            <img
                              className="ml-4"
                              src={Down_blue_arrow}
                              alt="Down_blue_arrow"
                            />
                          </Button>
                        )
                      ) : null}
                    </Col>
                  )}

                  <Col sm={24} md={12} lg={12}>
                    <OwlCarousel
                      className="owl-main owl-theme"
                      items={1}
                      nav={true}
                      loop
                      dots={true}
                    >
                      {TrancheRes?.tranche?.pictures?.length > 0
                        ? TrancheRes?.tranche?.pictures?.map((data, index) => {
                            return (
                              <div className="item" key={index}>
                                <img
                                  id="borrower-img"
                                  src={`${GlobalVariabels.VIEW_IMG}/${data}`}
                                  alt="Client"
                                />
                              </div>
                            );
                          })
                        : "No picture found"}
                    </OwlCarousel>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row
              className="mb-24 media-borrower-b-row t-desc-div invest"
              ref={(el) => (sectionsRef.current["invest"] = el)}
              gutter={window.innerWidth >= 768 ? 20 : 0}
              style={{ display: "flex" }}
            >
              <Col
                sm={24}
                md={12}
                lg={12}
                className="gutter-row media-right-pad"
                style={{ flexGrow: 1 }}
              >
                <Row style={{ height: "100%", gap: "10px" }}>
                  <StartInvesting
                    TrancheRes={TrancheRes}
                    setLoader={setLoader}
                    couponValue={couponAmount}
                  />
                  {TrancheRes?.reservation === null ? null : (
                    <ReservationDetails
                      TrancheRes={TrancheRes}
                      setLoader={setLoader}
                      setRenderComponent={setRenderComponent}
                    />
                  )}

                  <InvestDetails
                    TrancheRes={TrancheRes}
                    setLoader={setLoader}
                  />
                </Row>
              </Col>
              <Col
                sm={24}
                md={12}
                lg={12}
                className="infomation-div gutter-row medium-tranch-col"
                style={{ flexGrow: 1 }}
              >
                <BondDescription TrancheRes={TrancheRes} />
              </Col>
            </Row>

            {TrancheRes?.tranche?.details?.keyFinancialsCsv !== "" &&
              TrancheRes?.tranche?.details?.keyFinancialsCsv !== null && (
                <Row
                  className="media-margin left-marg-none mb-24 financial"
                  ref={(el) => (sectionsRef.current["financial"] = el)}
                >
                  <Col
                    xs={24}
                    sm={24}
                    md={
                      TrancheRes?.tranche?.details?.repaymentRate === ""
                        ? 24
                        : 24
                    }
                    lg={
                      TrancheRes?.tranche?.details?.repaymentRate === ""
                        ? 24
                        : 24
                    }
                    className={
                      TrancheRes?.tranche?.details?.repaymentRate === ""
                        ? "gutter-row"
                        : "gutter-row medium-tranch-col"
                    }
                  >
                    <AssetsInfo TrancheRes={TrancheRes} />
                  </Col>
                </Row>
              )}

            <Row
              gutter={10}
              className="mt-24 business-div business-description"
              ref={(el) => (sectionsRef.current["business-description"] = el)}
            >
              <Col sm={24} md={14} lg={14} className="gutter-row">
                <div className="infomation-div">
                  <Row>
                    <Col md={24} className="mb-24">
                      <p className="mt-0 tranch-head">Business</p>
                      {TrancheRes?.borrower?.description === "" ? (
                        "No Description found"
                      ) : (
                        <>
                          <p className="business-dec-borrower mb-0">
                            {expanded ? (
                              TrancheRes?.borrower?.description
                                .split("\n\n")
                                .map((paragraph, index) => (
                                  <p key={index} className="mb-0">
                                    {paragraph
                                      .split("\n")
                                      .map((line, lineIndex) => (
                                        <React.Fragment key={lineIndex}>
                                          {line}
                                          {lineIndex <
                                            paragraph.split("\n").length -
                                              1 && <br />}
                                        </React.Fragment>
                                      ))}
                                  </p>
                                ))
                            ) : (
                              <p className="mb-0">
                                {`${TrancheRes?.borrower?.description.slice(
                                  0,
                                  150
                                )}`}
                                {!expanded && "..."}
                              </p>
                            )}
                          </p>
                          {expanded ? (
                            <Button
                              onClick={toggleLess}
                              className="read-more-btn"
                            >
                              Read Less{" "}
                              <img
                                className="ml-4"
                                src={Down_blue_arrow}
                                alt="Down_blue_arrow"
                              />
                            </Button>
                          ) : (
                            <Button
                              onClick={toggleExpand}
                              className="read-more-btn"
                            >
                              Read More{" "}
                              <img
                                className="ml-4"
                                src={Down_blue_arrow}
                                alt="Down_blue_arrow"
                              />
                            </Button>
                          )}
                        </>
                      )}
                    </Col>
                    <Row className="trach-info">
                      <Col xs={12} lg={12}>
                        <p className="mb-5 mt-0 borrower-info-tag">Countries</p>
                      </Col>
                      <Col xs={12} lg={12} className="country-flag">
                        <p className="mb-5 mt-0 card-val-tag">
                          {TrancheRes?.borrower?.countries?.length > 0
                            ? TrancheRes?.borrower?.countries?.map(
                                (countryCode, index) => (
                                  <Tooltip
                                    title={getCountryNameByCode(
                                      countryList,
                                      countryCode
                                    )}
                                  >
                                    <span
                                      style={{
                                        filter:
                                          "drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.10))",
                                        border: "1px solid black",
                                      }}
                                      key={index}
                                      className={`mb-5 ml-5 mt-0 fi fi-${countryCode.toLowerCase()}`}
                                    />
                                  </Tooltip>
                                )
                              )
                            : "-"}
                        </p>
                      </Col>

                      {TrancheRes?.tranche?.trancheType === "REAL_ESTATE" ? (
                        <>
                          <Col xs={12} lg={12}>
                            <p className="mb-5 mt-0 borrower-info-tag">
                              Number of projects
                            </p>
                          </Col>
                          <Col xs={12} lg={12}>
                            <p className="mb-5 mt-0 card-val-tag">
                              {TrancheRes?.borrower?.numberOfProjects}
                            </p>
                          </Col>
                          <Col xs={12} lg={12}>
                            <p className="mb-5 mt-0 borrower-info-tag">
                              Current projects
                            </p>
                          </Col>
                          <Col xs={12} lg={12}>
                            <p className="mb-5 mt-0 card-val-tag">
                              {TrancheRes?.borrower?.currentProjects}
                            </p>
                          </Col>
                          <Col xs={12} lg={12}>
                            <p className="mb-5 mt-0 borrower-info-tag">
                              Experience
                            </p>
                          </Col>
                          <Col xs={12} lg={12}>
                            <p className="mb-5 mt-0 card-val-tag">
                              {TrancheRes?.borrower?.yearsOfExperience} years
                            </p>
                          </Col>
                        </>
                      ) : (
                        <>
                          <Col xs={12} lg={12}>
                            <p className="mb-5 mt-0 borrower-info-tag">
                              Kilde rating
                            </p>
                          </Col>
                          <Col xs={12} lg={12}>
                            <p className="mb-5 mt-0 card-val-tag">
                              {TrancheRes?.borrower?.creditRating
                                ? TrancheRes?.borrower?.creditRating
                                : "-"}
                            </p>
                          </Col>
                          <Col xs={12} lg={12}>
                            <p className="m-0 mb-5 borrower-info-tag">
                              Loans originated
                            </p>
                          </Col>
                          <Col xs={12} lg={12}>
                            {" "}
                            <p className="m-0 mb-5 card-val-tag">
                              {TrancheRes?.borrower?.loansOriginated === 0
                                ? "NA"
                                : formatCurrency(
                                    TrancheRes?.borrower?.currencySymbol,
                                    TrancheRes?.borrower?.loansOriginated
                                  )}
                            </p>
                          </Col>
                          <Col xs={12} lg={12}>
                            <p className="m-0 mb-5 borrower-info-tag">
                              Loan portfolio
                            </p>
                          </Col>
                          <Col xs={12} lg={12}>
                            <p className="m-0 mb-5 card-val-tag">
                              {TrancheRes?.borrower?.loansOriginated === 0
                                ? "NA"
                                : formatCurrency(
                                    TrancheRes?.borrower?.currencySymbol,
                                    TrancheRes?.borrower?.loansPortfolio
                                  )}
                            </p>
                          </Col>
                          <Col xs={24} lg={24}>
                            <p className="m-0 mb-5 borrower-info-tag">
                              Issuance on Kilde
                            </p>
                          </Col>

                          {TrancheRes?.borrower?.borrowingsOnPlatform?.map(
                            (res, index) => (
                              <>
                                <Col xs={12} lg={12}>
                                  <p className="m-0 mb-5 borrower-info-tag-issuance">
                                    Bond in {res?.currencyCode}
                                  </p>
                                </Col>
                                <Col xs={12} lg={12}>
                                  <p className="m-0 mb-5 card-val-tag">
                                    {formatCurrency(
                                      res?.currencySymbol,
                                      res?.borrowingOnPlatform
                                    )}
                                  </p>
                                </Col>
                              </>
                            )
                          )}

                          <Col xs={12} lg={12}>
                            <p className="m-0 mb-5 borrower-info-tag">
                              Founded year
                            </p>
                          </Col>
                          <Col xs={12} lg={12}>
                            <p className="m-0 mb-5 card-val-tag">
                              {TrancheRes?.borrower?.foundedYear}
                            </p>
                          </Col>
                        </>
                      )}

                      <Col xs={12} lg={12}>
                        <p className="m-0 mb-5 borrower-info-tag">
                          Number of employees
                        </p>
                      </Col>
                      <Col xs={12} lg={12}>
                        <p className="m-0 mb-5 card-val-tag">
                          {TrancheRes?.borrower?.numberOfEmployees === 0
                            ? "NA"
                            : TrancheRes?.borrower?.numberOfEmployees}
                        </p>
                      </Col>
                      <Col xs={12} lg={12}>
                        <p className="m-0 mb-5 borrower-info-tag">Site</p>
                      </Col>
                      <Col xs={12} lg={12}>
                        <p
                          className="m-0 mb-5 card-val-tag"
                          style={{ wordBreak: "break-word", maxWidth: "100%" }}
                        >
                          <Link
                            to={TrancheRes?.borrower?.companyWebsite}
                            className="borrower-desc-link"
                          >
                            {TrancheRes?.borrower?.companyWebsite}
                          </Link>
                        </p>
                      </Col>
                    </Row>
                  </Row>
                </div>
              </Col>
              <Col
                sm={24}
                md={10}
                lg={10}
                className="gutter-row medium-tranch-col"
              >
                <div className="infomation-div">
                  <Document TrancheRes={TrancheRes} />
                </div>
              </Col>
            </Row>

            {TrancheRes?.tranche?.paymentScheduleSummary?.payments?.length >
            0 ? (
              <Row
                className="mt-24 repayments"
                ref={(el) => (sectionsRef.current["repayments"] = el)}
              >
                <Col
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  className="gutter-row infomation-div"
                >
                  <OutStandingPayment TrancheRes={TrancheRes} />
                </Col>
              </Row>
            ) : null}

            {TrancheRes?.tranche?.capitalCallEnabled === true && (
              <Row
                className="mt-24 capital remove-margin-left-from-desktop"
                gutter={window.innerWidth >= 768 ? 20 : 0}
                ref={(el) => (sectionsRef.current["capital"] = el)}
              >
                <>
                  <Col
                    xs={24}
                    sm={24}
                    md={TrancheRes?.investment?.debentureCount > 0 ? 12 : 24}
                    lg={TrancheRes?.investment?.debentureCount > 0 ? 12 : 24}
                    className="gutter-row infomation-div"
                  >
                    <div style={{ height: "100%" }} ref={infoDivRef}>
                      <p className="mt-0 tranch-head mb-15">Early Redemption</p>
                      <div
                        className={
                          TrancheRes?.investment?.debentureCount > 0
                            ? "over-capital-desc-div"
                            : "capital-desc-div"
                        }
                      >
                        {TrancheRes?.investment?.nextCapitalCallDate ===
                        null ? (
                          <p className="business-dec-borrower">
                            No capital call dates available
                          </p>
                        ) : (
                          <>
                            <div className="text-container">
                              <p className="business-dec-borrower">
                                {`You have a right to require from the Borrower to execute
          the early repayment of the principal and accrued but unpaid
          interest on specific dates, as defined in the table below. You will
          need to give a ${TrancheRes?.tranche?.noticePeriodBeforeCapitalCall}-day
          advance notice to make the call.`}
                              </p>
                              <p className="business-dec-borrower">
                                {`Next capital call will be on ${britishFormatDate(
                                  TrancheRes?.investment?.nextCapitalCallDate
                                )}, and you need to submit an advance notice to us by ${britishFormatDate(
                                  TrancheRes?.investment
                                    ?.nextCapitalCallRequestDeadline
                                )}.`}
                              </p>
                            </div>
                            <div
                              className={`table-container ${
                                TrancheRes?.investment?.debentureCount > 0
                                  ? ""
                                  : "trach-info"
                              }`}
                            >
                              <Table
                                columns={capitalCallColumns}
                                dataSource={capitalCallData}
                                className="trache-table outstanding-pay-table"
                                pagination={false}
                              />
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </Col>
                  {TrancheRes?.investment?.debentureCount > 0 && (
                    <Col
                      sm={24}
                      md={12}
                      lg={12}
                      className="gutter-row media-right-pad medium-tranch-col"
                      style={{ flexGrow: 1 }}
                    >
                      <Row style={{ height: "100%" }}>
                        <StartCapitalCallRequest
                          TrancheRes={TrancheRes}
                          setCapitalRequestLoading={setCapitalRequestLoading}
                        />
                        <CapitalCallRequestLists
                          TrancheRes={TrancheRes}
                          capitalRequestLoading={capitalRequestLoading}
                          setCapitalRequestLoading={setCapitalRequestLoading}
                        />
                      </Row>
                    </Col>
                  )}
                </>
              </Row>
            )}

            {TrancheRes?.tranche?.details?.summary?.dealStructure && (
              <Row className="mt-24 media-borrower-t-row">
                <Col xs={24} md={24}>
                  <div className="infomation-div">
                    <p className="m-0 tranch-head">Deal structure</p>
                    <p className="business-dec-borrower mb-0">
                      {TrancheRes?.tranche?.details?.summary?.dealStructure}
                    </p>
                  </div>
                </Col>
              </Row>
            )}
          </Content>
        ) : (
          <div
            style={{
              position: "absolute",
              left: "50%",
              top: "50%",
              zIndex: 99,
              transform: "translate(-37px, -33px)",
            }}
          >
            <ReactLoading
              type="spin"
              color="var(--kilde-blue)"
              height={60}
              width={60}
            />
          </div>
        )}
        {openstateModal ? (
          <div ref={modalRef} className="media-screen-modal-tab-div">
            <div className="borrower-navigation-sub-div">
              {sections.map(
                ({ key, label, condition = true }) =>
                  condition && (
                    <p
                      key={key}
                      className={`notification-title mt-12 cursor-pointer ${
                        activeSection === key ? "active-borrower-tab" : ""
                      }`}
                      onClick={() => handleScrollToSection(key)}
                    >
                      {label}
                    </p>
                  )
              )}
            </div>
          </div>
        ) : null}
        {openstateModal && <div className="overlay"></div>}
        <div ref={fixedComponentRef} className="fixed-component">
          <p className="user-dropdown-link m-0">overview</p>
          <div>
            {openstateModal ? (
              <img
                src={Close_Icon}
                alt="close_icon"
                onClick={() => setOpenstateModal(false)}
              />
            ) : (
              <img
                src={Plus_Icon}
                alt="plus_icon"
                onClick={() => setOpenstateModal(true)}
              />
            )}
          </div>
        </div>
      </DashboardLayout>
    </div>
  );
};

export default Borrower;
